@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.auth-bg {
  background-image: url('./assets/img/auth-bg.jpg');
  filter: blur(6px);
}

@layer components {
  .hide-scroll {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
  vertical-align: baseline;
  margin: 0 5px;
}


@keyframes backdrop-fade {
  from {
    background: transparent;
  }

  to {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.app-back-drop-open {
  animation: backdrop-fade 300ms ease forwards;
}

.app-back-drop-close {
  animation: backdrop-fade 300ms ease backwards;
  animation-direction: reverse;
}

.app-modal {
  .app-modal-content-open {
    position: fixed;
    height: fit-content;

    @media (min-width: 768px) {
      animation: move-up 300ms ease forwards;
      -webkit-animation: move-up 300ms ease forwards;
    }
  }

  .app-modal-content-close {
    display: none;

    @media (min-width: 768px) {
      animation: move-up 300ms ease backwards;
      -webkit-animation: move-up 300ms ease backwards;
      animation-direction: reverse;
      -webkit-animation-direction: reverse;
    }
  }

  .app-modal-pop-up-content-open {
    position: fixed;
    height: fit-content;
    animation: move-up 300ms ease forwards;
    -webkit-animation: move-up 300ms ease forwards;
  }

  .app-modal-content-pop-up-close {
    display: none;
    animation: move-up 300ms ease backwards;
    -webkit-animation: move-up 300ms ease backwards;
    animation-direction: reverse;
    -webkit-animation-direction: reverse;
  }

  @keyframes move-up {
    from {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    to {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(45deg);
      }

      &.right {
        transform: rotate(-135deg) translate(-50%);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.panel-tabs {
  overflow-x: auto;
  white-space: nowrap;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: 10px 20px;
      display: inline-block;
      color: lightslategray;
      min-width: 100px;
      text-align: center;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background-color: transparent;
      transition: all 300ms ease-out;
      cursor: pointer;
      font-size: 13px;
      font-weight: bold;
    }

    .active {
      background-color: #7DD3FC;
      color: black;
    }
  }

}

.panel-tabs::-webkit-scrollbar {
  height: 4px;
}

/* Track */
.panel-tabs::-webkit-scrollbar-track {
  //box-shadow: inset 0 0 5px $primary-dark;
  background-color: transparent;
  border-radius: 0px;
}

.panel-tabs::-webkit-scrollbar-thumb {
  background: rgba(168, 178, 191, 0.29) !important;
  border-radius: 2px !important;
}

.dragging  {
  opacity: 0.8;
  transform: scale(1.02);
  background: #f0f0f0;
}
.landing-contact {
  svg {
    display: inherit;
    vertical-align: inherit;
  }
}